import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';

import { AppComponent } from './app.component';
import { IonicStorageModule } from '@ionic/storage-angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './core/interceptors/auth-interceptor';
import { StorageService } from './core/services/storage.service';
import { ErrorHandlerService } from './core/interceptors/error-handler.service';
import { HttpInterceptorService } from './core/interceptors/http-interceptor.service';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from "ngx-spinner";
@NgModule({
    declarations: [AppComponent],
  imports: [
        BrowserAnimationsModule,
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        CoreModule,
        IonicStorageModule.forRoot(),
        HttpClientModule,
        ToastrModule.forRoot(),
        NgxSpinnerModule.forRoot({ type: 'ball-clip-rotate' })
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        StorageService,
        {
            provide: APP_INITIALIZER,
            useFactory: (ss: StorageService) => () => ss.init(),
            deps: [StorageService],
            multi: true,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpInterceptorService,
        multi: true
      },
      { provide: ErrorHandler, useClass: ErrorHandlerService }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
