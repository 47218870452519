import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { StorageService } from './storage.service';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Params } from '@angular/router';
import { Page } from '../types/base.types';
import { promise } from 'protractor';
import { error } from 'console';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  auth$ = new BehaviorSubject(true);
  authenticated$: Observable<boolean>;

  constructor(private http: HttpClient, private storage: StorageService) {
    this.authenticated$ = this.auth$.pipe(map((x) => !!x));
  }

  login(username: string, password: string) {
    return this.http
      .post<any>('api/Authentication/GrantToken', { username, password })
      .pipe(
        switchMap((result) =>
          this.storage.set('token', result.token).then(() => result)
        ),
        switchMap((result) =>
          this.storage.set('role', result.role).then(() => result)
        ),
        switchMap((result) =>
          this.storage.set('userProduct', username).then(() => result)
        ),
        tap((result) => {
          this.auth$.next(result); 
        }),
        //catchError((err, caught) => {
        //  return throwError(error);
        //})
      );
  }

  logout() {
    this.storage.remove('token');
    this.storage.remove('role');
    this.storage.remove('userProduct');
    this.storage.remove('selectedPage');

    this.auth$.next(false);
    window.location.href = '\login';
  }

  async checkCurrentToken() {
    await this.storage.get('token').then((x) => {
      if (!x) {
        this.auth$.next(false);
      }
    });
  }

  async asCurrentToken(): Promise<boolean> {
    return await this.storage.get('token');
  }

  async getSavedUsername() {
    return await this.storage.get('username');
  }
  //used for product availability
  async getSavedUserProduct() {
    return await this.storage.get('userProduct');
  }

  async getSavedRole() {
    return await this.storage.get('role');
  }

  async getSavedPages() {
    return await this.storage.get('pages');
  }

  async setSavedUsername(username: string) {
    return await this.storage.set('username', username);
  }

  async getSelectedPage() {
    return await this.storage.get('selectedPage') as Page;
  }

  async setSelectedPage(page: Page) {
    return await this.storage.set('selectedPage', page);
  }
  async removeSelectedPage() {
    return await this.storage.remove('selectedPage');
  }

  async removeSavedUsername() {
    return await this.storage.remove('username');
  }

  // auth api calls
  get<T>(url: string): Observable<T> {
    return this.http.get<T>('api/' + url);
  }

  getWithParams<T>(url: string, filter: any): Observable<T> {
    if (filter) {
      const queryString = this.toQueryString(filter);
      url += '?' + queryString;
    }
    return this.http.get<T>('api/' + url);
  }

  getWithParamsForFile(url: string, filter: any): Observable<any> {
    if (filter) {
      const queryString = this.toQueryString(filter);
      url += '?' + queryString;
    }
    /*return this.http.get<Blob>('api/' + url, { observe: 'response', type: 'text/json; charset=utf-8' })*/
    return this.http.get('api/' + url, { observe: 'response', responseType: 'blob' });
  }

  post<T>(url: string, data: any): Observable<T> {
    console.log('url: ', url, ' data: ', data);
    return this.http.post<T>('api/' + url, data);
  }

  put<T>(url: string, data: any): Observable<T> {
    console.log('url: ', url, ' data: ', data);
    return this.http.put<T>('api/' + url, data);
  }

  // test api calls
  getUserInfo() {
    return this.http.get('api/Authentication/GetUserInfo', {
      responseType: 'text',
    });
  }

  getSampleData() {
    return this.http.get('api/Authentication/GetSampleData');
  }

  private toQueryString(query): string {
    const parts = [];
    // eslint-disable-next-line guard-for-in
    for (const property in query) {
      const value = query[property];
      if (value != null && value !== undefined) { parts.push(encodeURIComponent(property) + '=' + encodeURIComponent(value)); }
    }

    return parts.join('&');
  }

  private setParameter(routerParams: Params): HttpParams {
    let queryParams = new HttpParams();
    for (const key in routerParams) {
      if (routerParams.hasOwnProperty(key)) {
        queryParams = queryParams.set(key, routerParams[key]);
      }
    }
    return queryParams;
  }
}
