// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delete-button {
  margin-bottom: 1.2vh;
  margin-right: -0.1vw;
  width: 2vh;
  height: 2vh;
  line-height: 2vw;
}

.activate-button {
  margin-bottom: -1.8vh;
  margin-right: -0.1vw;
  width: 2vh;
  height: 2vh;
  line-height: 2vw;
  background-color: red;
}

.page-button {
  margin-right: 1vw;
}

.selected-page {
  text-decoration: underline;
  font-weight: bold;
}

ion-fab.close-hover {
  visibility: hidden;
}

ion-buttons:hover ion-fab.close-hover {
  visibility: visible;
}

.delete-modal .modal-wrapper {
  height: 30%;
  width: 90%;
  border-radius: 15px;
}

ion-modal {
  --height: 12%;
  --width: 50%;
  --border-radius: 16px;
}

.modal-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  min-height: 56px;
}

ion-icon {
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/app/core/header/page-button/page-button.component.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,oBAAA;EACA,UAAA;EACA,WAAA;EACA,gBAAA;AACF;;AACA;EACE,qBAAA;EACA,oBAAA;EACA,UAAA;EACA,WAAA;EACA,gBAAA;EACA,qBAAA;AAEF;;AACA;EACE,iBAAA;AAEF;;AACA;EACE,0BAAA;EACA,iBAAA;AAEF;;AAMA;EACE,kBAAA;AAHF;;AAMA;EACE,mBAAA;AAHF;;AAOE;EACI,WAAA;EACA,UAAA;EACA,mBAAA;AAJN;;AASA;EACE,aAAA;EACA,YAAA;EACA,qBAAA;AANF;;AASA;EACE,eAAA;EACA,SAAA;EACA,WAAA;EACA,gBAAA;AANF;;AASA;EACE,oBAAA;AANF","sourcesContent":[".delete-button {\r\n  margin-bottom: 1.2vh;\r\n  margin-right: -0.1vw;\r\n  width: 2vh;\r\n  height: 2vh;\r\n  line-height: 2vw;\r\n}\r\n.activate-button {\r\n  margin-bottom: -1.8vh;\r\n  margin-right: -0.1vw;\r\n  width: 2vh;\r\n  height: 2vh;\r\n  line-height: 2vw;\r\n  background-color: red;\r\n}\r\n\r\n.page-button {\r\n  margin-right: 1vw;\r\n}\r\n\r\n.selected-page {\r\n  text-decoration: underline;\r\n  font-weight: bold;\r\n}\r\n\r\n//.inactive-page-button {\r\n//  margin-right: 1vw;\r\n//  background: lightgrey;\r\n//}\r\n\r\nion-fab.close-hover {\r\n  visibility: hidden;\r\n}\r\n\r\nion-buttons:hover ion-fab.close-hover {\r\n  visibility: visible;\r\n}\r\n\r\n.delete-modal {\r\n  .modal-wrapper {\r\n      height: 30%;\r\n      width: 90%;\r\n      border-radius: 15px;\r\n    }\r\n}\r\n\r\n\r\nion-modal {\r\n  --height: 12%;\r\n  --width: 50%;\r\n  --border-radius: 16px;\r\n}\r\n\r\n.modal-footer {\r\n  position: fixed;\r\n  bottom: 0;\r\n  width: 100%;\r\n  min-height: 56px;\r\n}\r\n\r\nion-icon {\r\n  pointer-events: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
