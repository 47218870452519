import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { PageService } from '../services/page.service';
import { combineLatest } from 'rxjs';
import { map, tap, take } from 'rxjs/operators';
import { Constants } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class PageGuardAuthService  {
  role: string;
  pages: any;
  constructor(private auth: AuthService) {
  }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return this.auth.asCurrentToken().then(r => {
      return r;
    });
  }

  private check(route: ActivatedRouteSnapshot): boolean {
    if (route.data !== undefined && (route.data.role == Constants.AdminPageRole || route.data.role == Constants.CdfAdminRole)) {
      return this.role === Constants.CdfAdminRole;
    } else {
      return true;
    }
    return false;
  }
}
