import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IonModal, ModalController } from '@ionic/angular';
import { map, tap } from 'rxjs/operators';
import { PageService } from '../../services/page.service';
import { Page } from '../../types/base.types';

@Component({
  selector: 'app-page-button',
  templateUrl: './page-button.component.html',
  styleUrls: ['./page-button.component.scss'],
})
export class PageButtonComponent implements OnInit {
  @ViewChild('deleteModal') deleteModal: IonModal;
  @ViewChild('activateModal') activateModal: IonModal;
  @Input() page: Page;
  @Input() selected: number;
  @Output() pageLinkClicked = new EventEmitter(); 
  constructor(
    private pageService: PageService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {}
  onWillDismiss(event: Event) {
    console.log('dismiss event: ', event);
  }

  cancelDelete() {
    return this.deleteModal.dismiss(null, 'cancel');
  }

  deletePage() {
    //this.pageService.deletePage(this.page.id);
    return this.deleteModal.dismiss(null, 'confirm');
  }

  cancelActivate() {
    return this.activateModal.dismiss(null, 'cancel');
  }

  activatePage() {
    this.page.active = true;
    //this.pageService.updatePage(this.page);
    return this.activateModal.dismiss(null, 'confirm');
  }

  deactivatePage() {
    this.page.active = false;
    //this.pageService.updatePage(this.page);
    return this.activateModal.dismiss(null, 'confirm');
  }

  pageClicked() {
    this.pageLinkClicked.emit(this.page);
    //this.pageService.pageSelect(this.page.id);
  }

  openActivateModal() {
    this.activateModal.present();
  }
}
