import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { bottom } from '@katoid/angular-grid-layout/lib/utils/react-grid-layout.utils';
import {
  BehaviorSubject,
  combineLatest,
  from,
  merge,
  Observable,
  of,
  throwError,
} from 'rxjs';
import { catchError, map, retry, switchMap, tap } from 'rxjs/operators';
import { Page } from '../types/base.types';
import { AuthService } from './auth.service';
import { LocalModificationService } from './local-modification.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class PageService {
  //temp data, used for testing.  remove once api calls are setup
  maxPos;
  $pageSelected = new BehaviorSubject(new Page());

  isAuthorized = this.storage.get('token');

  // Check if the user is authorized before making the HTTP request
  $existingPages: Observable<Array<Page>> = from(this.storage.get('token'))
    .pipe(
      switchMap((isAuthorized: string | null) => {
        if (isAuthorized) {
          return this.auth.get<{ pages: Array<Page> }>('Page/GetAll')
            .pipe(
              map((x) => {
                x.pages.sort((a, b) => a.position - b.position);
                this.homePage = x.pages[0];
                return x.pages;
              }),
              tap((y) => {
                //console.log(isAuthorized);

                this.storage.set('pages', y).then();
              })
            );
        } else {
          // Return an observable with an empty array if the user is not authorized
          return of([]);
        }
      })
    );
  //$pages: Observable<Array<Page>> =  this.accessControl.flatMap((res: any) => {
  //  if (res.accessGranted) {
  //    return this.dataService.getData(res.id);
  //  } else {
  //    return Observable.of(null);
  //  }
  //})
  //this.$existingPages.
  //$pages: Observable<Array<Page>> = combineLatest([
  //  this.$existingPages,
  //]).pipe(
  //  map(([existingPages]) => {
  //    let retVal = existingPages;
  //    this.maxPos = Math.max(...retVal.map((y) => y.position));
  //    if (this.maxPos === -Infinity) {
  //      this.maxPos = 0;
  //    }
  //    return retVal;
  //  })
  //  // tap((x) => {
  //  //   console.log('combined pages object: ', x);
  //  // })
  //);

  // $Params = this.route.params.pipe(
  //   tap((x) => {
  //     console.log('x in service: ', x);
  //   })
  // );
  $PageParams = new BehaviorSubject(null);

  // $PageParams = combineLatest([this.$Params]);

  // $PageParams = this.route.params.pipe(
  //   map((param) => param.getValue()),
  //   tap((y) => {
  //     console.log('y: ', y);
  //   })
  // );

  private homePage: Page;
  constructor(
    private localMod: LocalModificationService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private toastController: ToastController,
    private storage: StorageService) {
    this.auth.authenticated$.subscribe(result => { });
  }

  // getPageId(pageName: string): number {
  //   let pageId;
  //   this.$pages.subscribe((x) => {
  //     pageId = x.find((y) => y.name === pageName).id;
  //   });
  //   return pageId;
  // }

  getPage(pageId: number): Page {
    let page;
    this.$existingPages.subscribe((x) => {
      page = x.find((y) => y.id === pageId);
    });
    return page;
  }

  getHomePage(): Page {
    return this.homePage;
  }

  getPages(): Observable<Array<Page>> {
    return this.auth.get<{ pages: Array<Page> }>('Page/GetAll')
      .pipe(
        map((x) => {
          x.pages.sort((a, b) => a.position - b.position);
          this.homePage = x.pages[0];
          return x.pages;
        }),
        tap((y) => {
          this.storage.set('pages', y).then();
        })
      );
  }
  getPagesFromStorage() {

  }

  //// update page
  //updatePage(updatedPage: Page) {
  //  //make api call here to update page
  //  //handle result and update observable
  //  this.updatedPages.push(updatedPage);
  //  this.$updatedPages.next(this.updatedPages);
  //}

  //// update pages
  //updatePages(updatedPages: Array<Page>) {
  //  //make api call here to update page positioning
  //  //handle result and update observable
  //}

  //// add new page
  //addPage(newPage: Page) {
  //  console.log('this.maxPos: ', this.maxPos);
  //  newPage.position = this.maxPos + 1;
  //  console.log(newPage);

  //  const returnedValue = this.auth
  //    .post<{ pageBasic: Page }>('Page/Add', newPage)
  //    .subscribe(
  //      (result) => {
  //        console.log(result);
  //        this.presentResultToast(
  //          'bottom',
  //          'success',
  //          'Page Added Successfully'
  //        );
  //      },
  //      (error) => {
  //        this.presentResultToast('bottom', 'danger', error);
  //        console.log(error);
  //      }
  //    );
  //  return returnedValue;
  //}

  //// delete page
  //deletePage(pageId: number) {
  //  this.localMod.resetPageChanges(pageId);
  //  const returnedValue = this.auth
  //    .post('Page/Delete', { id: pageId })
  //    .subscribe(
  //      (result) => {
  //        // handle results and update observable
  //        this.presentResultToast(
  //          'bottom',
  //          'success',
  //          'Page Deleted Successfully'
  //        );
  //      },
  //      (error) => {
  //        this.presentResultToast('bottom', 'danger', error);
  //        console.log(error);
  //      }
  //    );
  //  return returnedValue;
  //}

  pageSelect(pageId: number) {
    this.$PageParams.next(pageId);
  }

  async presentResultToast(
    position: 'top' | 'middle' | 'bottom',
    result: 'success' | 'danger',
    message: string
  ) {
    const toast = await this.toastController.create({
      message,
      duration: 1500,
      position,
      color: result,
    });

    await toast.present();
  }
}
