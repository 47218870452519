import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Constants } from '../constants';
import { NotificationService, NotificationData, HorizontalPostion, InformationType, VerticalPostion }
  from '../services/notification.service';
/*import { ApplicationInsightsService } from "@services/application-insights.service";*/

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {
  
  constructor(private injector: Injector) { }

  handleError(error: any): void {
    if (!!error) { console.error(error); }

    // this.injector.get<ApplicationInsightsService>(ApplicationInsightsService)
    //    .logException(error);
  }

  logError(error: any, showMessage: boolean = false): void {

    //console.error(error);
    const errorMessage = this.handleBadRequest(error);

    //this.injector.get<ApplicationInsightsService>(ApplicationInsightsService)
    //   .logException(new Error(error));

    if (showMessage && !!errorMessage) {
      const data = this.createDataNotification(errorMessage, this.getTitle(error), InformationType.error);
      this.injector.get<NotificationService>(NotificationService).showNotification(data);
    }
  }

  logInfo(info: string, showMessage: boolean = false): void {
    //console.info(info);
    if (showMessage) {
      const data = this.createDataNotification(info, '', InformationType.info);
      this.injector.get<NotificationService>(NotificationService).showNotification(data);
    }
  }
  createDataNotification(message: string, title: string, type: InformationType): NotificationData {
    // eslint-disable-next-line max-len
    const data: NotificationData = { message, horizontalPostion: HorizontalPostion.right, title, type, verticalPostion: VerticalPostion.top };
    return data;
  }
  logWarning(warning: string, showMessage: boolean = false): void {

    console.log(warning);

    //this.injector.get<ApplicationInsightsService>(ApplicationInsightsService)
    //.logTrace(warning);

    if (showMessage) {
      const data = this.createDataNotification(warning, 'warning', InformationType.warning);
      this.injector.get<NotificationService>(NotificationService).showNotification(data);
    }
  }

  //private handleNotFound = (error: HttpErrorResponse): string => {
  //  this.router.navigate(['/404']);
  //  return error.message;
  //}
  private handleBadRequest = (error: HttpErrorResponse): string => {
    let message = '';
    if (error.status === 500) {
      return error.message;
    } else if (error.status === 400) {
      const values = Object.values(error.error.Errors);
      values.map((m: string) => {
        message += m + '<br>';
      })
      return message.slice(0, -4);
    } else if (error.status === 408) {
      return Constants.TimeOutError;
    }
    //else if (error.status === 403) {
    //  throw error;
    //}
    if (error.status === 401 || error.status === 403 || error.status === 404) {
      throw error;
    }
  }

  private getTitle(error) {
    if (!!error) {
      return error?.title ?? error?.error?.title;
      //let message = '';
      //const values = Object.values(error.error);
      //values.map((m: string) => {
      //  message += m + '<br>';
      //})
      //return message;
    } else {
      return '';
    }
  }

  //else {
  //  return error.error ? error.error : error.message;
  //}


  private getErrorMessage(error: any) {

    if (error === undefined || error === null)
      return '';

    if (error.data) {
      if (error.data.messageDetail)
        return error.data.messageDetail;
      if (error.data.exceptionMessage)
        return error.data.exceptionMessage;
      if (error.data.message)
        return error.data.message;

      return error.data;
    } else if (error) {
      // if(!!error.errorCode &&  error.errorCode === "token_renewal_error"){
      //    window.location.reload();
      // }
      if (error.message)
        return error.message;
    }

    return error;
  }

}
