import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { StorageService } from '../services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(private storage: StorageService, private router: Router) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.storage.get('token')).pipe(
      map((authToken) => {
        if (authToken) {
          req = req.clone({
            headers: req.headers.set('Authorization', 'Bearer ' + authToken),
          });
        }
        return req;
      }),
      switchMap((authReq) =>
        next.handle(authReq).pipe(
          map((event: HttpEvent<any>) => event),
          catchError((err) => {
            //if (err.status === 401 || err.status === 403) {
            //  this.router.navigate(['/login']);
            //  //return;
            //} else {
            //  const error = err.message || err.statusText;
            //  return throwError(error);
            //}
            if (err.status === 401 || err.status === 403) {
              this.router.navigate(['/login']);
            }
            //else if (err.status === 403) {
            //  return throwError(err);
            //}
            const error = err.message || err.statusText;
            return throwError(error);
          })
        )
      )
    );
  }
}
