import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { PageService } from '../services/page.service';
import { IonSelect } from '@ionic/angular';
import { Page } from '../types/base.types';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @ViewChild('adminPageSelect') adminPageSelect: IonSelect;
  isAdmin = false;

  $PageParams = this.pageService.$PageParams;
  $Pages = this.pageService.$existingPages;
  $pageSelected = this.pageService.$pageSelected;
  public hideLabel = false;
  pages = [];
  adminPages = [];
  activePage: number;
  adminSelected: number;
  $headerPages = combineLatest([this.$PageParams, this.$pageSelected]).pipe(
    map(([pageParams, selected]) => {
      let activePage = {};
      let filterPages;
      if (pageParams === null && selected === null) {
        this.activePage = this.pages.find((y) => y.name === this.router.url)?.id;
      } else if (selected === null && pageParams != null) {
        this.activePage = pageParams;
      } else if (selected != null) {
        this.activePage = selected.id;
      }
      this.pages.sort((a, b) => a.position - b.position);
      // eslint-disable-next-line prefer-const
      filterPages = this.pages.filter(x => !x.isContentPage);
      return { pages: this.pages, filterPages };
    })
  );

  constructor(
    public authService: AuthService,
    public pageService: PageService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    //forkJoin({
    //  authenticatedResult: this.authService.authenticated$,
    //  pagesResult: this.pageService.getPages()
    //}).subscribe({
    //  next: (data: any) => {
    //    if (data.authenticatedResult) {
    //      this.pages = data.pagesResult;
    //    }
    //  },
    //  error: (error) => {
    //  },
    //  complete: () => {  }
    //});

    //find best way to do this.

    this.authService.authenticated$.subscribe(result => {
      if (result && window.location.pathname !== '/login') {
        this.pageService.getPages().subscribe(
          {
            next: (x: any) => {
              this.pages = x;
              this.adminPages = this.pages.filter(x => !x.isContentPage || x.forAdmin);

              if (this.activePage == null && !!this.route.snapshot.queryParamMap.get('pageName')) {
                const page = this.pages.find((y) => y.name === this.route.snapshot.queryParamMap.get('pageName'));
                this.activePage = page.id;
              } else {
                const page = this.pages.find((y) => this.router.url.includes(y.path));
                if (!!page && !page?.isContentPage) {
                  this.adminPageSelect.selectedText = this.setText(page.displayName);
                  this.adminPageSelect.value = page.id;
                }
              }
            },
            error: (error) => {

            },
            complete: () => { }
          });
      }
    });
  }

  //compareWith(o1, o2) {
  //  //if (!o1 || !o2) {
  //  //  return o1 === o2;
  //  //}

  //  //if (Array.isArray(o2)) {
  //  //  return o2.some((o) => o.id === o1.id);
  //  //}

  //  return o1.id === this.adminPageSelect;
  //}

  ngAfterViewInit() {
    this.authService.getSavedRole().then((x) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      x === 'CDFAdministrators' ? this.isAdmin = true : this.isAdmin = false;
    });
  }

  logout() {
    this.authService.logout();
  }

  goto(page: any) {
    if (!!page) {
      //(ionFocus) send the page id, not the pageobject.
      if (!isNaN(page)) {
        page = this.pages.find(x => x.id === page);
      }
      if (page.isContentPage && !page.forAdmin) {
        this.pageService.$pageSelected.next(page);
        if (!!this.adminPageSelect) {
          this.adminPageSelect.selectedText = 'ADMIN';
          this.adminPageSelect.value = '';
        }

        this.hideLabel = true;
        this.router.navigate([`${page.path}`], {
          queryParams: { pageId: null, contentId: null, pageName: page.name },
          queryParamsHandling: 'merge'
        });
      } else {
        this.hideLabel = true;
        this.adminPageSelect.selectedText = page.displayName?.toUpperCase();
        this.pageService.$pageSelected.next(null);
        this.authService.setSelectedPage(page);
        //need this because we have content with : forAdmin = true and isContentPage = true
        if (page.isContentPage) {
          this.router.navigate([`${page.path}`], {
            queryParams: { pageId: null, contentId: null, pageName: page.name },
            queryParamsHandling: 'merge'
          });
        } else {
          this.router.navigate([page.path]);
        }
      }
    }
  }

  setText(value: string) {
    return value.toUpperCase();
  }

  goHome() {
    let page = this.pages.find(x => x.name === 'Home');
    this.goto(page);
  }
}
