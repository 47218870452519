import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonModal } from '@ionic/angular';
import { Page } from '../../types/base.types';

@Component({
  selector: 'app-page-reorder',
  templateUrl: './page-reorder.component.html',
  styleUrls: ['./page-reorder.component.scss'],
})
export class PageReorderComponent implements OnInit {
  @ViewChild(IonModal) modal: IonModal;
  @Input() pages: Array<Page> = [];
  constructor() {}

  ngOnInit() {}

  cancel() {
    this.pages.sort((a, b) => a.position - b.position);
    this.modal.dismiss(null, 'cancel');
  }

  confirm() {
    for (let index = 0; index < this.pages.length; index++) {
      this.pages[index].position = index + 1;
    }
    console.log('Confirm: ', this.pages);
    this.modal.dismiss(null, 'confirm');
  }

  onWillDismiss(event: Event) {
    this.pages.sort((a, b) => a.position - b.position);
    console.log('dismiss event: ', event);
  }

  reorderItems(event) {
    const itemMove = this.pages.splice(event.detail.from, 1)[0];
    this.pages.splice(event.detail.to, 0, itemMove);
    event.detail.complete();
    console.log('pages: ', this.pages);
  }
}
