import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

import { HeaderComponent } from './header/header.component';
import { PageReorderComponent } from './header/page-reorder/page-reorder.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PageButtonComponent } from './header/page-button/page-button.component';

@NgModule({
  declarations: [
    HeaderComponent,
    PageReorderComponent,
    PageButtonComponent,
  ],
  imports: [CommonModule, RouterModule, IonicModule, ReactiveFormsModule],
  exports: [HeaderComponent],
})
export class CoreModule {}
