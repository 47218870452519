// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-modal {
  --border-radius: 16px;
}

.reorder-title {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/core/header/page-reorder/page-reorder.component.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;AACF;;AAEA;EACE,kBAAA;AACF","sourcesContent":["ion-modal {\r\n  --border-radius: 16px;\r\n}\r\n\r\n.reorder-title {\r\n  text-align: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
