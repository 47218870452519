import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from './core/services/auth.service';
import { StorageService } from './core/services/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  currentYear = new Date().getFullYear();

  constructor(private auth: AuthService, public router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.auth.checkCurrentToken().then(x => {
      setTimeout(() => { }, 2000);
    });
  }
}
