import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { Constants } from './core/constants';
import { PageGuardService } from './core/guards/page-guard.service';
import { DocumentationEditorComponent } from './cdf-documents-editor/documentation-editor.component';
import { PageGuardAuthService } from './core/guards/page-guard-auth.service';

const routes: Routes = [
  { path: '', redirectTo: '/render-content?pageName=Home', pathMatch: 'full' },
  //{
  //  path: '',
  //  loadChildren: () => import('./pages/gb/gb.module').then((m) => m.GbModule),
  //},
  // {
  //   path: 'admin',
  //   loadChildren: () =>
  //     import('./admin/admin.module').then((m) => m.AdminPageModule),
  // },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'documentation-editor',
    loadChildren: () => import('./cdf-documents-editor/document-editor.module').then((m) => m.DocumentEditorModule),
    canActivate: [PageGuardService],
    data: {
      role: [Constants.AdminPageRole]
    }
  },
  {
    path: 'admin-content',
    loadChildren: () => import('./admin-content/admin-content.module').then((m) => m.AdminContentModule),
    // canActivate: [PageGuardService],
    // data: {
    //   role: [Constants.AdminPageRole]
    // }
  },
  {
    path: 'render-content',
    loadChildren: () => import('./render-content/render-content.module').then((m) => m.RenderContentModule),
    canActivate: [PageGuardAuthService],
  },
  //{
  //  path: 'download-file',
  //  loadChildren: () => import('./render-content/render-content.module').then((m) => m.RenderContentModule),
  //  canActivate: [PageGuardService],
  //},
  //{
  //  path: 'render-content/:contentId/:pageId',
  //  loadChildren: () => import('./render-content/render-content.module').then((m) => m.RenderContentModule),
  //  canActivate: [PageGuardService],
  //},
  {
    path: 'document-admin',
    loadChildren: () => import('./admin-document/admin-document.module').then((m) => m.AdminDocumentModule),
  },
  {
    path: 'product-availability',
    loadChildren: () => import('./product/product.module').then((m) => m.ProductModule)
  },
];

@NgModule({
  imports: [
    /*RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, enableTracing: true }),*/
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      anchorScrolling: 'enabled'
    }),

  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  // constructor(private router: Router, private pageService: PageService) {
  //   let test: Array<Route>;
  //   this.pageService.getActivePages().forEach((page) => {
  //     test.push({ path: page.name, component: GridboxComponent });
  //   });
  // this.router.resetConfig([...test, ...this.router.config]);
  // }
}
