import { Component } from '@angular/core';
import { KtdGridLayout } from '@katoid/angular-grid-layout';

export class Page {
  id: number;
  name: string;
  displayName: string;
  position: number;
  active: boolean;
  //created: Date;
  //lastModified: Date;
  //modifiedBy: string;
  path: string;
  isContentPage: boolean;
  forAdmin: boolean;
  contentId: string;
}

export class Content {
  id: number;
  typeId: number;
  name: string;
  displayName: string;
  status: string;
  content: string;
  comments: string;
  createDate: Date;
  lastModified: Date;
  modifiedBy: string;
}

export class PageContent {
  id: number;
  pageId: number;
  contentId: number;
  gridX: number;
  gridY: number;
  gridW: number;
  gridH: number;
  createDate: Date;
  lastModified: Date;
  modifiedBy: string;
  page: Page;
}

export class CustomerContent {
  custId: string;
  contentId: string;
  version: number;
  active: boolean;
  content: string;
  comments: string;
  title: string;
  effectiveStart: Date;
  effectiveEnd: Date;
  //lastModified: Date;
  //modifiedBy: string;
}

export class PageModel {
  page: Page;
  pcc: Array<{
    pageContent: PageContent;
    content: Content;
  }>;
}

export class PageModelN {
  page: Page;
  pageContent: PageContent[];
  content: Content[];
  layout: KtdGridLayout;
}

export class ContentType {
  id: number;
  displayName: string;
  component: any;
}

export interface ContentComponent {
  data: any;
}

export class SelectOption {
  id: number;
  label: string;
}
//Actual
export class PageContentBox {
  id: number;
  pageId: number;
  version: number;
  comments: string;
  title: string;
  active: boolean;
  effectiveStart: Date;
  effectiveEnd: Date;
  modifiedBy: string;
  code: string;
  currentLanguage: string;
  isNewVersion: boolean;
  idLast: number;
  boxes: Array<BoxContent> = [];
}

export class BoxContent {
  id: number;
  pageContentId: number;
  version: number;
  active: boolean;
  content: string;
  title: string;
  cssClass: string;
  height: number;
  width: number;
  x: number;
  y: number;
}

export class Product {
  id: string;
  artist: string;
  title: string;
  upc: string;
  wms: string;
  quantityAvailable: number;
  dateAvailable: Date;
}

export class DocumentLink {
  id: number;
  title: string;
  description: string;
  version: string;
  lastUpdate: string | null;
  link: string;
  createdUser: string;
}

export class ContactType {
  id: number;
  name: string;
  tel: string;
  email: string;
  avatar: string;
}

export enum CaseDelete {
  File,
  Files,
  Directory
}
