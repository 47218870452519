import { Injectable } from '@angular/core';
import { KtdGridLayout } from '@katoid/angular-grid-layout';
import {
  BehaviorSubject,
  combineLatest,
  defer,
  from,
  Observable,
  Subject,
} from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Content, PageContent } from '../types/base.types';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class LocalModificationService {
  // Observables for box addition
  $StorageAddedBoxesAction = new BehaviorSubject(null);
  $StorageAddedBoxes: Observable<
    Array<{ newContentBlock: PageContent; newContent: Content }>
  > = defer(() =>
    from(
      this.storage.get(this.addKey).then((x) => {
        if (x) {
          return x;
        } else {
          return this.storage.set(this.addKey, []);
        }
      })
    )
  );

  $AddedBoxes = combineLatest([
    this.$StorageAddedBoxes,
    this.$StorageAddedBoxesAction,
  ]).pipe(
    map(([storageAddedBoxes, storageAddedBoxesAction]) => {
      if (storageAddedBoxesAction != null) {
        return storageAddedBoxesAction;
      }
      return storageAddedBoxes;
    })
  );

  // Observables for box removal
  $StorageRemovedBoxesAction = new BehaviorSubject(null);
  $StorageRemovedBoxes: Observable<
    Array<{ newContentBlock: PageContent; newContent: Content }>
  > = defer(() =>
    from(
      this.storage.get(this.removeKey).then((x) => {
        if (x) {
          return x;
        } else {
          return this.storage.set(this.removeKey, []);
        }
      })
    )
  );

  $RemovedBoxes = combineLatest([
    this.$StorageRemovedBoxes,
    this.$StorageRemovedBoxesAction,
  ]).pipe(
    map(([storageRemovedBoxes, storageRemovedBoxesAction]) => {
      if (storageRemovedBoxesAction != null) {
        return storageRemovedBoxesAction;
      }
      return storageRemovedBoxes;
    })
    // tap((x) => {
    //   console.log('removed boxes observable: ', x);
    // })
  );

  // Observables for layout updates
  $StorageLayoutUpdatesAction = new BehaviorSubject(null);
  $StorageLayoutUpdates: Observable<
    Array<{ pageId: number; layout: KtdGridLayout }>
  > = defer(() =>
    from(
      this.storage.get(this.layoutKey).then((x) => {
        if (x) {
          return x;
        } else {
          return this.storage.set(this.layoutKey, []);
        }
      })
    )
  );

  $LayoutUpdates = combineLatest([
    this.$StorageLayoutUpdates,
    this.$StorageLayoutUpdatesAction,
  ]).pipe(
    map(([storageLayoutUpdates, storageLayoutUpdatesAction]) => {
      if (storageLayoutUpdatesAction != null) {
        return storageLayoutUpdatesAction;
      }
      return storageLayoutUpdates;
    })
  );

  // local storage keys
  private addKey = 'addedBoxes';
  private removeKey = 'removedBoxes';
  private layoutKey = 'layoutUpdates';

  constructor(private storage: StorageService) {}

  // add box to local storage
  addBox(
    addedBoxes: Array<{ newContentBlock: PageContent; newContent: Content }>
  ) {
    this.storage.set(this.addKey, addedBoxes);
    this.$StorageAddedBoxesAction.next(addedBoxes);
  }

  // remove box to local storage
  removeBox(
    removedBoxes: Array<{ newContentBlock: PageContent; newContent: Content }>
  ) {
    this.storage.set(this.removeKey, removedBoxes);
    this.$StorageRemovedBoxesAction.next(removedBoxes);
  }

  // updates locally stored values to match page modifications
  async updateLayout(pageId: number, layout: KtdGridLayout) {
    await this.storage.get(this.layoutKey).then((current) => {
      const existingIndex = current.findIndex((x) => x.pageId === pageId);
      if (existingIndex >= 0) {
        current[existingIndex] = { pageId, layout };
        // console.log('exists');
      } else {
        current.push({ pageId, layout });
      }
      this.storage.set(this.layoutKey, current);
      this.$StorageLayoutUpdatesAction.next(current);
      // console.log('existing index: ', existingIndex);
      // this.$StorageLayoutUpdatesAction.next(null);
    });
  }

  // reset local storage of all changes
  async resetPageChanges(pageId: number) {
    await this.resetAddedBoxes(pageId);
    await this.resetRemovedBoxes(pageId);
    await this.resetLayoutUpdates(pageId);
  }

  // reset local storage of added boxes on a page
  async resetAddedBoxes(pageId: number) {
    await this.storage.get(this.addKey).then((x) => {
      const indexes: Array<number> = [];
      let spliceCount = 0;
      for (let index = 0; index < x.length; index++) {
        if (x[index].newContentBlock.pageId === pageId) {
          indexes.push(index);
        }
      }
      indexes.forEach((ind) => {
        x.splice(ind - spliceCount, 1);
        spliceCount++;
      });
      this.storage
        .set(this.addKey, x)
        .then(() => this.$StorageAddedBoxesAction.next(x));
    });
  }

  // reset local storage of removed boxes on a page
  async resetRemovedBoxes(pageId: number) {
    await this.storage.get(this.removeKey).then((x) => {
      const indexes: Array<number> = [];
      let spliceCount = 0;
      for (let index = 0; index < x.length; index++) {
        if (x[index].newContentBlock.pageId === pageId) {
          indexes.push(index);
        }
      }
      indexes.forEach((ind) => {
        x.splice(ind - spliceCount, 1);
        spliceCount++;
      });
      this.storage
        .set(this.removeKey, x)
        .then(() => this.$StorageRemovedBoxesAction.next(x));
    });
  }

  // reset local storage of layout changes on a page
  async resetLayoutUpdates(pageId: number) {
    await this.storage.get(this.layoutKey).then((x) => {
      const indexes: Array<number> = [];
      for (let index = 0; index < x.length; index++) {
        if (x[index].pageId === pageId) {
          indexes.push(index);
        }
      }
      indexes.forEach((ind) => {
        x.splice(ind, 1);
      });
      this.storage
        .set(this.layoutKey, x)
        .then(() => this.$StorageLayoutUpdatesAction.next(x));
    });
  }

  // move all of add and remove to this service
  // will require implementation of these two functions
  getMaxContentId() {}
  getMaxPageContentId() {}

  // layout updated
}
