import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { PageService } from '../services/page.service';
import { combineLatest } from 'rxjs';
import { map, tap, take } from 'rxjs/operators';
import { Constants } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class PageGuardService  {
  role: string;
  pages: any;
  constructor(private auth: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (this.role === undefined) {
      this.auth.getSavedRole().then(r => {
        this.role = r;
        return this.check(route);
      });
    }
    else {
      return this.check(route);
    }
    return false;
  }

  private check(route: ActivatedRouteSnapshot): boolean {
    if (route.data !== undefined && (route.data.role === Constants.AdminPageRole || route.data.role === Constants.CdfAdminRole)) {
      return this.role === Constants.CdfAdminRole;
    } else {
      return true;
    }
    return false;
  }
}
