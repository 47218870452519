// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.global_container {
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 7px;
}

ion-content::part(background) {
  background: rgb(228, 228, 228) !important;
  background: linear-gradient(0deg, rgb(228, 228, 228) 0%, rgb(89, 177, 199) 12%, rgb(51, 99, 130) 100%) !important;
}

.loader-css-class {
  --background: #261bff;
  --spinner-color: #ffffff;
}

.footer-container {
  display: flex;
  justify-items: center;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 30%;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,UAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;AACF;;AAEA;EACE,gBAAA;EACA,iBAAA;EACA,kBAAA;AACF;;AAKA;EAGE,yCAAA;EACA,iHAAA;AAJF;;AAQA;EACE,qBAAA;EACA,wBAAA;AALF;;AASA;EACE,aAAA;EACA,qBAAA;EACA,mBAAA;EACA,iBAAA;EACA,mBAAA;EACA,iBAAA;AANF","sourcesContent":["body {\r\n  margin: 0;\r\n  padding: 0;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  min-height: 100vh;\r\n}\r\n\r\n.global_container {\r\n  margin-left: 10%;\r\n  margin-right: 10%;\r\n  margin-bottom: 7px;\r\n  //background: rgb(228, 228, 228) !important;\r\n  //background: linear-gradient(0deg, rgba(228, 228, 228, 1) 0%, rgba(89, 177, 199, 1) 12%, rgba(51, 99, 130, 1) 100%) !important;\r\n}\r\n\r\n//TODO: apply to all app\r\nion-content::part(background) {\r\n  //background: #336581 !important;\r\n\r\n  background: rgb(228, 228, 228) !important;\r\n  background: linear-gradient(0deg, rgba(228, 228, 228, 1) 0%, rgba(89, 177, 199, 1) 12%, rgba(51, 99, 130, 1) 100%) !important;\r\n\r\n}\r\n\r\n.loader-css-class {\r\n  --background: #261bff;\r\n  --spinner-color: #ffffff;\r\n}\r\n\r\n\r\n.footer-container {\r\n  display: flex;\r\n  justify-items: center;\r\n  align-items: center;\r\n  padding-top: 10px;\r\n  padding-bottom: 5px;\r\n  padding-left: 30%;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
