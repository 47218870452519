import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

export interface NotificationData {
  title: string;
  message: string;
  verticalPostion: VerticalPostion,
  horizontalPostion: HorizontalPostion,
  type: InformationType
}

export enum VerticalPostion{
  top = 'top',
  bottom = 'bottom'
}

export enum HorizontalPostion{
  right = 'right',
  center = 'center',
  left = 'left'
}

export enum InformationType {
  info = 'info',
  warning = 'warning',
  success = 'success',
  danger = 'danger',
  primary = 'primary',
  error = 'error',
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private toastr: ToastrService) {}

  private getDisplayMessage(data: NotificationData){
    let message = `<span>${data.message} </span>`;
    return message;
  }

  showNotification(data: NotificationData){
    if (data.type === InformationType.success) {
      this.toastr.success(this.getDisplayMessage(data), data.title, {
        enableHtml: true,
        disableTimeOut: false,
        timeOut:7000,
        positionClass: `toast-${data.verticalPostion}-${data.horizontalPostion}` 
      });
    } else {
      this.toastr.error(this.getDisplayMessage(data), data.title, {
        enableHtml: true,
        disableTimeOut: false,
        timeOut:7000,
        positionClass: `toast-${data.verticalPostion}-${data.horizontalPostion}` 
      });
    }
  }

  showMessageSuccess(message: string, title: string) {
    let data: NotificationData = { message: message, horizontalPostion: HorizontalPostion.right, title, type: InformationType.success, verticalPostion: VerticalPostion.top };
    this.showToast(data);
  }

  showMessageWarning(message: string, title: string) {
    let data: NotificationData = { message: message, horizontalPostion: HorizontalPostion.right, title, type: InformationType.warning, verticalPostion: VerticalPostion.top };
    this.showToast(data);
  }

  showMessageError(message: string, title: string) {
    let data: NotificationData = { message: message, horizontalPostion: HorizontalPostion.right, title, type: InformationType.error, verticalPostion: VerticalPostion.top };
    this.showToast(data);
  }

  showMessageInformation(message: string, title: string) {
    let data: NotificationData = { message: message, horizontalPostion: HorizontalPostion.right, title, type: InformationType.info, verticalPostion: VerticalPostion.top };
    this.showToast(data);
  }

  showToast(data: NotificationData) {
    switch (data.type) {
      case InformationType.success: {
        this.toastr.success(this.getDisplayMessage(data), data.title, {
          enableHtml: true,
          disableTimeOut: false,
          timeOut: 7000,
          positionClass: `toast-${data.verticalPostion}-${data.horizontalPostion}`
        });
        break;
      }
      case InformationType.info: {
        this.toastr.info(this.getDisplayMessage(data), data.title, {
          enableHtml: true,
          disableTimeOut: false,
          timeOut: 7000,
          positionClass: `toast-${data.verticalPostion}-${data.horizontalPostion}`
        });
        break;
      }
      case InformationType.error: {
        this.toastr.error(this.getDisplayMessage(data), data.title, {
          enableHtml: true,
          disableTimeOut: false,
          timeOut: 7000,
          positionClass: `toast-${data.verticalPostion}-${data.horizontalPostion}`
        });
        break;
      }
      case InformationType.warning: {
        this.toastr.warning(this.getDisplayMessage(data), data.title, {
          enableHtml: true,
          disableTimeOut: false,
          timeOut: 7000,
          positionClass: `toast-${data.verticalPostion}-${data.horizontalPostion}`
        });
        break;
      }
    }
  }
}
